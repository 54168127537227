.product-image {
  width: 60%;
  margin-top: 1rem;
  display: inline-block;
}

.packages-container {
  position: relative;

  &:before {
    content: "";
    height: 99px;
    border-left: 4px dotted #4273a2;
    position: absolute;
    top: -99px;
    z-index: -1;
  }
}
